
import Vue from "vue";
import * as api from "@/api";
import MemberListFilter from "./MemberListFilter.vue";

export default Vue.extend({
  name: "MemberList",
  components: {
    MemberListFilter
  },
  data() {
    return {
      memberList: [] as api.Member[],
      pagination: {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      level: [] as api.MemberLevel[],
      queryParams: {
        userName: null,
        nickName: null,
        registeredAtFrom: null,
        registeredAtTo: null,
        memberLevelId: null,
        sorting: "createdAt DESC",
        skipCount: 0,
        maxResultCount: 10
      },
      selectedItems: [] as api.Member[],
      loading: false
    };
  },
  watch: {
    queryParams: {
      async handler() {
        await this.fetchData();
      },
      deep: true
    },
    pagination: {
      handler(pagination) {
        this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.queryParams.maxResultCount = pagination.pageSize;
      },
      deep: true
    }
  },
  async created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "会员列表",
        path: "memberList"
      }
    ]);
    await this.getLevel();
    await this.fetchData();
  },
  methods: {
    edit(item) {
      this.$store.commit("memberInfo", item);
      this.$router.push({
        path: "/user/memberEdit"
      });
    },
    changeInp(data) {
      Object.assign(this.queryParams, data);
    },
    handleSorting(val) {
      this.queryParams.sorting = val;
    },
    async fetchData() {
      this.loading = true;
      const res = await api.getMember(this.queryParams);
      this.loading = false;
      if (!api.error(res)) {
        this.pagination.totalCount = res.data.totalCount;
        this.memberList = res.data.items;
      }
    },
    async getLevel() {
      const res = await api.getMemberLevel();
      if (!api.error(res)) {
        this.level = res.data;
      }
    },
    async deleteMember(selectedItems) {
      // 删除
      if (this.selectedItems.length > 0) {
        const res = await api.delMember(selectedItems.map(i => i.id));
        if (!api.error(res)) {
          this.fetchData();
        } else {
          this.$message.error(res.error.message);
        }
      }
    },
    handleSelectionChange(selection) {
      this.selectedItems = selection;
    },
    async isEnabled(selectedItems, isEnabled: boolean) {
      // 是否启用
      // 批量的话过滤掉重复操作的
      const ids = Array.isArray(selectedItems)
        ? selectedItems.filter(i => i.isEnabled !== isEnabled).map(i => i.id)
        : [selectedItems.id];
      if (ids.length === 0) return;
      const res = isEnabled ? await api.putMemberEnable(ids) : await api.putMemberDisable(ids);
      if (!api.error(res)) {
        selectedItems.forEach(e => {
          e.isEnabled = isEnabled;
        });
      } else {
        selectedItems.isEnabled = !isEnabled; // 单个的话再回到原先状态
        this.$message.error(res.error.message);
      }
    },
    editGrowthValue() {
      console.log(11);
    },
    handleBatchCommand(command) {
      if (this.selectedItems.length === 0) {
        this.$message({
          type: "warning",
          message: "未选中任何会员"
        });
        return;
      }
      switch (command) {
        case 0:
          this.editGrowthValue();
          break;
        case 1:
          this.isEnabled(this.selectedItems, true);
          break;
        case 2:
          this.isEnabled(this.selectedItems, false);
          break;
        case 3:
          this.deleteMember(this.selectedItems);
          break;
        default:
      }
    }
  }
});
