
  import Vue from 'vue';
  import moment from 'moment';

  export default Vue.extend({
    name: 'MemberFilter',
    props: {
      level: {
        type: Array,
      },
    },
    data() {
      return {
        date: '',
        queryParams: {
          userName: null,
          nickName: null,
          registeredAtFrom: null as string | null,
          registeredAtTo: null as string | null,
          memberLevelId: null,
        },
      };
    },
    watch: {
      date(val) {
        this.queryParams.registeredAtFrom = val ? moment(val[0]).format('YYYY/MM/DD') : null;
        this.queryParams.registeredAtTo = val ? moment(val[1]).format('YYYY/MM/DD') : null;
      },
    },
    methods: {
      reset() {
        this.queryParams = {
          userName: null,
          nickName: null,
          registeredAtFrom: null,
          registeredAtTo: null,
          memberLevelId: null,
        };
        this.date = '';
        this.$emit('changeInp', JSON.parse(JSON.stringify(this.queryParams)));
      },
      submit() {
        this.$emit('changeInp', JSON.parse(JSON.stringify(this.queryParams)));
      },
    },
  });
